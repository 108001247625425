import React from 'react';

// Function to format trait types
const formatTraitType = (traitType) => {
    const words = traitType.split('_');
    const formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return formattedWords;
};

const Attributes = ({ id, attributes }) => {
    // Define the trait types to exclude
    const excludedTraitTypes = ["Total Rarity", "PFP Rarity", "Game Rarity"];

    // Filter the attributes to exclude specific trait types
    const filteredAttributes = attributes.filter(attribute => !excludedTraitTypes.includes(attribute.trait_type));

    // Get the rank attributes
    const totalRank = attributes.find(attr => attr.trait_type === 'Total Rank')?.value;
    const pfpRank = attributes.find(attr => attr.trait_type === 'PFP Rank')?.value;
    const gameRank = attributes.find(attr => attr.trait_type === 'Game Rank')?.value;

    return (
        <div>
            <h1>Attributes for ID: {id}</h1>
            <ul>
                {filteredAttributes.map((attribute, index) => (
                    <li key={index}>
                        {formatTraitType(attribute.trait_type)}: {attribute.value}
                    </li>
                ))}
                {totalRank && <li>{formatTraitType('Total Rank')}: {totalRank}</li>}
                {pfpRank && <li>{formatTraitType('PFP Rank')}: {pfpRank}</li>}
                {gameRank && <li>{formatTraitType('Game Rank')}: {gameRank}</li>}
            </ul>
        </div>
    );
};

export default Attributes;
