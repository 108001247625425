import React, { useState } from 'react';
import './App.css';
import AttributeForm from './AttributeForm';
import Attributes from './Attributes';
import data from './data_with_rarity.json'; // Import your JSON data

function App() {
    const [id, setId] = useState('');
    const [attributes, setAttributes] = useState([]);

    const handleSubmit = (id) => {
        // Logic to get attributes by ID from your JSON data
        const attributesById = data[id]?.data?.extension?.attributes || [];
        setId(id);
        setAttributes(attributesById);
    };

    return (
        <div className="App">
            <AttributeForm onSubmit={handleSubmit} />
            {attributes.length > 0 && <Attributes id={id} attributes={attributes} />}
        </div>
    );
}

export default App;
