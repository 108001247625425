import React, { useState } from 'react';

const AttributeForm = ({ onSubmit }) => {
    const [id, setId] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(id);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                placeholder="Enter ID"
                value={id}
                onChange={(e) => setId(e.target.value)}
            />
            <button type="submit">View Attributes</button>
        </form>
    );
};

export default AttributeForm;
